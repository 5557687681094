import { FormEvent, useEffect } from 'react'
import { MobileNumberField } from '@ui/components/inputs/MobileNumberField'
import { SubmitButton } from '@ui/components/buttons/SubmitButton'
import { Spinner } from '@ui/components/Spinner'
import { VerificationText } from '@web-app/components/auth/VerificationText'
import { useEventTracking } from '@web-app/hooks/useEventTracking'

export function LoginPhoneForm({
  handleSubmit,
  loading,
  phone,
  setPhone,
}: {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  loading: boolean
  phone: string
  setPhone: (value: string) => void
}) {
  const { trackEvent } = useEventTracking()

  useEffect(() => {
    trackEvent('view_sign_in_phone', { comp: 'LoginPhoneForm' })
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6">
        <MobileNumberField
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          disabled={loading}
        />
      </div>
      <div className="mt-4">
        <VerificationText />
      </div>
      <SubmitButton disabled={loading}>
        {loading ? (
          <>
            <Spinner className="mr-2 h-5 w-5 text-white" />
            Loading...
          </>
        ) : (
          'Sign in with phone'
        )}
      </SubmitButton>
    </form>
  )
}
