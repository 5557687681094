import { FormEvent } from 'react'
import Link from 'next/link'
import { CodeField } from '@ui/components/inputs/CodeField'
import { CheckboxField } from '@ui/components/inputs/Fields'
import { SubmitButton } from '@ui/components/buttons/SubmitButton'
import { Spinner } from '@ui/components/Spinner'
import { ObfuscatedPhoneNumber } from '@ui/components/ObfuscatedPhoneNumber'

export function SmsCodeVerificationForm({
  handleSubmit,
  loading,
  smsCode,
  setSmsCode,
  phone,
  showMarketingConsent = false,
  setSmsMarketing,
}: {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  loading: boolean
  smsCode: string
  setSmsCode: (value: string) => void
  phone: string
  showMarketingConsent?: boolean
  setSmsMarketing?: (value: boolean) => void
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6 pb-4">
        <p>
          Please enter the 6-digit code we sent to your phone number ending in{' '}
          <ObfuscatedPhoneNumber phone={phone} />.
        </p>
        <CodeField
          value={smsCode}
          onChange={(event) => setSmsCode(event.target.value)}
          disabled={loading}
        />
        {showMarketingConsent && setSmsMarketing && (
          <CheckboxField
            onChange={(event) => setSmsMarketing(event.target.checked)}
            disabled={loading}
            label={
              <>
                I agree to receive ATM.com&apos;s fraud alerts, special offers,
                and promotions via SMS. Consent is not a condition to purchase.
                Message & date rates may apply. Message frequency varies. Reply
                &quot;STOP&quot; to unsubscribe. View our{' '}
                <Link
                  href="https://www.atm.com/terms-of-service"
                  target="_blank"
                  className="text-blueberry-700"
                >
                  Terms of Service
                </Link>{' '}
                or{' '}
                <Link
                  href="https://www.atm.com/privacy-policy"
                  target="_blank"
                  className="text-blueberry-700"
                >
                  Privacy Policy
                </Link>{' '}
                for details.
              </>
            }
          />
        )}
      </div>
      <SubmitButton disabled={loading}>
        {loading ? (
          <>
            <Spinner className="mr-2 h-5 w-5 text-white" />
            Loading...
          </>
        ) : (
          'Next'
        )}
      </SubmitButton>
    </form>
  )
}
