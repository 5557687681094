import Link from 'next/link'
import clsx from 'clsx'
import { useSession } from '@web-app/hooks/useSession'
import { LandingFooter } from '@ui/layout/LandingFooter'
import { LogoBlack } from '@ui/components/Svg'

export function WebAuthLayout({
  className,
  title,
  heading,
  children,
  altSignIn,
  altRegister,
}: {
  className?: string
  title?: string
  heading?: React.ReactNode
  children?: React.ReactNode
  altSignIn?: boolean
  altRegister?: boolean
}) {
  const { sessionData } = useSession()
  const user = sessionData?.user
  const isLoggedIn = Boolean(user)

  return (
    <>
      <div className={clsx('bg-white text-black', className)}>
        <main className="flex min-h-screen overflow-hidden">
          <div className="mx-auto flex w-full max-w-xl flex-1 flex-col p-4 sm:px-4 sm:py-8">
            <div className="flex flex-1 flex-col sm:items-center sm:justify-center">
              <div className="flex flex-col items-center justify-center">
                <Link href="/">
                  <span className="sr-only">ATM.com</span>
                  <LogoBlack className="h-10 w-auto sm:h-12 sm:w-[162px]" />
                </Link>
              </div>
              {heading}
              {(children || title) && (
                <div className="my-4 p-4 sm:mx-0 sm:my-8 sm:flex-none sm:py-0">
                  {title && (
                    <h2 className="h4 mb-8 text-center font-bold">{title}</h2>
                  )}
                  {children}
                </div>
              )}
              {(altSignIn || altRegister) && (
                <div className="my-4">
                  {altSignIn && (
                    <p className="body-4 text-center">
                      Already have an ATM.com account?{' '}
                      <Link
                        href="/auth/login"
                        className="text-blueberry-700 hover:text-blueberry-900 cursor-pointer whitespace-nowrap no-underline hover:underline"
                      >
                        Sign in
                      </Link>
                    </p>
                  )}
                  {altRegister && (
                    <p className="body-3 text-center">
                      Don’t have an ATM.com account?{' '}
                      <Link
                        href="/auth/register"
                        className="text-blueberry-700 hover:text-blueberry-900 cursor-pointer whitespace-nowrap no-underline hover:underline"
                      >
                        Sign up
                      </Link>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
      <LandingFooter isLoggedIn={isLoggedIn} />
    </>
  )
}
