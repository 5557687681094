import { TextField } from '@ui/components/inputs/Fields'

export function CodeField({
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'>) {
  return (
    <TextField
      label="Verification Code"
      name="code"
      type="text"
      autoComplete="one-time-code"
      required
      {...props}
    />
  )
}
