import { FormEvent, useEffect } from 'react'
import { EmailField } from '@ui/components/inputs/EmailField'
import { PasswordField } from '@ui/components/inputs/PasswordField'
import { SubmitButton } from '@ui/components/buttons/SubmitButton'
import { Spinner } from '@ui/components/Spinner'
import { VerificationText } from '@web-app/components/auth/VerificationText'
import { useEventTracking } from '@web-app/hooks/useEventTracking'

export function LoginEmailForm({
  handleSubmit,
  loading,
  email,
  setEmail,
  password,
  setPassword,
}: {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  loading: boolean
  email: string
  setEmail: (value: string) => void
  password: string
  setPassword: (value: string) => void
}) {
  const { trackEvent } = useEventTracking()

  useEffect(() => {
    trackEvent('view_sign_in_email', { comp: 'LoginEmailForm' })
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-6">
        <EmailField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled={loading}
        />
        <PasswordField
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          disabled={loading}
        />
      </div>
      <div className="mt-4">
        <VerificationText />
      </div>
      <SubmitButton disabled={loading}>
        {loading ? (
          <>
            <Spinner className="mr-2 h-5 w-5 text-white" />
            Loading...
          </>
        ) : (
          'Sign in with email'
        )}
      </SubmitButton>
    </form>
  )
}
