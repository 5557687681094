import type { GetServerSideProps } from 'next'
import type { SessionContext } from '@web-app/types'
import Head from 'next/head'
import { useState } from 'react'
import Router from 'next/router'
import { toast } from 'react-hot-toast'
import { LinkButton } from '@ui/components/buttons/LinkButton'
import { withRequiredOrOptionalSession } from '@web-app/hoc/withSession'
import { WebAuthLayout } from '@web-app/components/layout/WebAuthLayout'
import { LoginEmailForm } from '@web-app/components/auth/LoginEmailForm'
import { LoginPhoneForm } from '@web-app/components/auth/LoginPhoneForm'
import { SmsCodeVerificationForm } from '@web-app/components/auth/SmsCodeVerificationForm'
import { useSession } from '@web-app/hooks/useSession'
import { OAuthLinks } from '@web-app/components/auth/OAuth'

const LoginPage = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const [isEmailForm, setIsEmailForm] = useState<boolean>(true)
  const [isSmsVerificationVisible, setIsSmsVerificationVisible] =
    useState(false)
  const [smsCode, setSmsCode] = useState('')
  const { setSessionData } = useSession()

  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await fetch('/api/auth/login-with-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })

      if (response.ok) {
        const data = await response.json()
        await setSessionData(data)
        setIsSuccessful(true)
        toast.success('Successfully logged in!')
        Router.push('/onboarding/complete-registration')
      } else {
        const data = await response.json()
        throw new Error(data.message)
      }
    } catch (error) {
      console.error(`Error in handleEmailSubmit: ${String(error)}`)
      if (error instanceof Error) {
        toast.error(error.message)
        setPassword('')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handlePhoneSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await fetch('/api/auth/login-with-phone', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone }),
      })

      if (response.ok) {
        setIsSmsVerificationVisible(true)
        toast.success('SMS code sent!')
      } else {
        const data = await response.json()
        throw new Error(data.message)
      }
    } catch (error) {
      console.error(`Error in handlePhoneSubmit: ${String(error)}`)
      if (error instanceof Error) {
        toast.error(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleVerifySmsCode = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await fetch('/api/auth/verify-sms-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone,
          code: smsCode,
        }),
      })

      if (response.ok) {
        const data = await response.json()
        await setSessionData(data)
        setIsSuccessful(true)
        toast.success('Successfully logged in!')
        Router.push('/onboarding/complete-registration')
      } else {
        const data = await response.json()
        throw new Error(data.message)
      }
    } catch (error) {
      console.error(`Error in handleVerifySmsCode: ${String(error)}`)
      if (error instanceof Error) {
        toast.error(error.message)
        setSmsCode('')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Head>
        <title>Sign in to your account</title>
      </Head>
      <WebAuthLayout
        title="Log in to your account"
        // altRegister={true}
      >
        {isSmsVerificationVisible ? (
          <SmsCodeVerificationForm
            handleSubmit={handleVerifySmsCode}
            loading={isLoading || isSuccessful}
            smsCode={smsCode}
            setSmsCode={setSmsCode}
            phone={phone}
          />
        ) : (
          <>
            <OAuthLinks textSign="in" />
            {isEmailForm ? (
              <LoginEmailForm
                handleSubmit={handleEmailSubmit}
                loading={isLoading || isSuccessful}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
              />
            ) : (
              <LoginPhoneForm
                handleSubmit={handlePhoneSubmit}
                loading={isLoading || isSuccessful}
                phone={phone}
                setPhone={setPhone}
              />
            )}
            <div className="mt-6 text-center">
              <LinkButton onClick={() => setIsEmailForm(!isEmailForm)}>
                {isEmailForm ? 'Sign in with phone' : 'Sign in with email'}
              </LinkButton>
            </div>
          </>
        )}
      </WebAuthLayout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps =
  withRequiredOrOptionalSession(
    { required: false },
    async (context: SessionContext) => {
      const { session } = context
      const { user } = session

      if (user) {
        // Check if the user is already logged in
        return {
          redirect: {
            destination: '/',
            permanent: false,
          },
        }
      }

      // No redirect needed, render the page
      return {
        props: {},
      }
    },
  )

export default LoginPage
