export function ObfuscatedPhoneNumber({
  phone,
  className,
}: {
  phone: string
  className?: string
}) {
  const lastFourDigits = phone.slice(-4)
  return <span className={className}>****{lastFourDigits}</span>
}
